.root {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  color: var(--text-faded-color);
  margin-top: 10px;
}

.root p {
  display: inline-block;
}