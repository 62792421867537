.root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.pageTitleContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.topBarRight {
  display: flex;
  align-items: flex-end;
  gap: 25px;
}

.pageTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
}

.networkIndicator {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  text-transform: capitalize;
}

.networkIcon {
  margin-bottom: 2px;
}

.topBarLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.breadCrumbs {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.crumb {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: var(--crumb-color);
}

.crumb:hover span {
  color: black;
}

.crumb:hover svg {
  color: var(--primary-color);
}
