.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.sectionTitle {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color: #3a3a3a;
  margin-top: 10px;
}

.fontSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 80px;
  gap: 10px;
}

.doubleSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.root.disabled > * {
  opacity: 0.25;
  pointer-events: none;
}
