@import '../../../styles/variables.scss';

.card {
  background: $background-color;
  border-radius: $border-radius;
  padding: 30px;
  box-shadow: $box-shadow;
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: fit-content;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.title {
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  color: $text-primary;
  margin: 0;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: $text-primary;

    &::after {
      content: ' *';
      color: #ff0000;
    }
  }

  input,
  select,
  textarea {
    padding: 7px 10px;
    border: 1px solid $border-color;
    border-radius: 5px;
    background: $background-color;
    color: #000;
    font-size: 16px;
    line-height: 21px;
    font-weight: 400;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    box-sizing: border-box;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('../../../images/chevron-down.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  padding-right: 40px !important;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.completedState {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .label {
    font-size: 12px;
    color: $text-secondary;
  }

  .value {
    font-size: 14px;
    color: var(--text-primary);
  }
}

.resolutionsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.allResolutionsButton {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 5px 10px;
  background: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  svg > rect {
    stroke: #fff;
  }
}

.individualResolutions {
  color: $text-secondary;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  padding-bottom: 2px;
  border: none;
  border-bottom: 1px solid $text-secondary;
  background: none;
  outline: none;
}

.resolutionsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 12px;
}

.resolutionCheckbox {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid var(--border);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  input {
    display: none;
  }

  &.selected {
    background: $primary-light;
    border-color: $primary-color;
    color: $primary-color;
  }
}

.formGrid {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.row {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  &.threeColumns {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &.twoColumns {
    grid-template-columns: 1fr 1fr;
  }

  &.fullWidth {
    grid-template-columns: 1fr;
  }
}

.formFooter {
  display: flex;
  justify-content: flex-end;
  padding-top: 16px;
  border-top: 1px solid var(--border);
}

.submitButton {
  height: 40px;
  padding: 0 24px;
  background: $primary-color;
  color: var(--white);
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: var(--primary-hover);
  }

  &:disabled {
    background-color: var(--disabled);
    cursor: not-allowed;
  }
}

.description {
  margin-left: 20px;
  color: #666666;
  font-size: 12px;
  font-style: italic;
}

textarea {
  font-family: 'Open Sans', sans-serif;
}

.labelGroup {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 10px;
}

.subtitle {
  color: var(--text-disabled);
  font-size: 12px;
  margin-bottom: 1px;
}

.placementBoxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.placementsSection {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--primary-color);
  animation: spin 0.5s linear infinite;
}

.loadingIcon {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: -1.5px;
}
