.root {
  width: 100%;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  justify-items: center;
  width: 100%;
}

.previewWrapper {
  width: 100%;
  background: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.preview {
  background: #fff;
  position: absolute;
  pointer-events: none;
}
