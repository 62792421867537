.aiScoresText {
  font-size: 16px;
  line-height: 16px;
  position: relative;
  top: 1px;
}

.aiScoresSwitch {
  margin: auto 0;
}
