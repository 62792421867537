.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin: 24px 0;
}

.pageButton {
  min-width: 32px;
  height: 32px;
  border: 1px solid #e0e0e0;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #616161;
  
  &:hover:not(:disabled) {
    background: #f5f5f5;
    border-color: #2B3990;
    color: #2B3990;
  }
  
  &.active {
    background: #2B3990;
    border-color: #2B3990;
    color: white;
  }
  
  &.ellipsis {
    border: none;
    cursor: default;
    
    &:hover {
      background: transparent;
      color: #2B3990
    }
  }
  
  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

.arrow {
  @extend .pageButton;
  padding: 0 8px;
  
  svg {
    width: 16px;
    height: 16px;
  }
}
