.notEditable {
  pointer-events: none;
}

.resolution {
  display: grid;
  grid-template-columns: 15px 1fr;
  gap: 10px;
  position: relative;
  pointer-events: none;
}

.resolution:not(:last-child) {
  padding-bottom: 10px;
}

.leftContent {
  position: relative;
}

.verticalBar {
  position: absolute;
  top: 0;
  left: calc(50% - 2px);
  width: 2px;
  height: calc(100% + 10px);
  background-color: #d0d4ef;
}

.stackIcon {
  position: relative;
  background-color: white;
}

.resolution:first-child .verticalBar {
  top: 20px;
  height: calc(100% - 10px);
}

.resolution:first-child .rightContent {
  padding-top: 3px;
}

.resolution:last-child .verticalBar {
  height: 0;
  top: 0;
}

.halfRoundBox {
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #d0d4ef;
  border-left: 2px solid #d0d4ef;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: -7px;
  left: calc(50% - 2px);
}

.textContent {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  margin: 0 0 5px;
}

.resolutionLabel {
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
  color: #000;
  margin: 0 0 5px;
}
