.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.resolutionButtons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.resolutionButton {
  display: flex;
  align-items: center;
  gap: 5px;
  background: var(--bg-primary-soft);
  border-radius: 5px;
  padding: 5px 10px;
  border: 1px solid var(--bg-primary-soft);
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  position: relative;
}

.resolutionButton.darker {
  background: #eff1f3;
}

.resolutionButton.brighter {
  background: #ffffff;
}

.resolutionButton.active {
  background: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
}

.resolutionButton:hover {
  background: var(--primary-hover);
  color: white;
  border-color: var(--primary-hover);
}

.aspectRatioIcon {
  border: 1.5px solid #868686;
  display: inline-block;
  border-radius: 2px;
}

.resolutionButton.active .aspectRatioIcon {
  border-color: white;
}

.aspectRatioWrapper {
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorMessage {
  color: var(--error-color);
  font-size: 12px;
  overflow: hidden;
  margin: 0;
}

.disabledIcon,
.errorIcon {
  color: var(--error-color);
  background: #fff;
  border-radius: 100px;
  border: 1.5px solid #fff;
}

.errorIcon {
  color: var(--error-color);
  background: #fff;
  border-radius: 1px;
  border: 1.5px solid #fff;
}

.overIcons {
  position: absolute;
  font-size: 16px;
  top: -9px;
  right: 5px;
}

.error {
  border-color: var(--error-color);
}

.resolutionButtons.smaller {
  gap: 5px;
}

.resolutionButtons.smaller .resolutionButton {
  padding: 5px 7px;
  gap: 3px;
}

.resolutionButtons.smaller .aspectRatioWrapper {
  height: 15px;
  width: 15px;
}
