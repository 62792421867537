.root {
  display: flex;
  gap: 10px;
}

.isDisabled .disableButton {
  color: var(--error-soft);
}

.isDisabled > button:not(.disableButton) {
  opacity: 0.25;
  pointer-events: none;
}
