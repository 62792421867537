.file-uploader {

    .thumbnails {
        border: 1px solid #d7d7d7;
        margin: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        .thumbnail {
            display: block;
            padding: 10px;
            margin: 0px;
            border-radius: 5px;
        }
    }

}
