.networks {
  display: flex;
  gap: 10px;
}

.networkButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
}

.networkIcon {
  width: 22px;
  height: 22px;
  fill: currentColor;
}
