.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border: none;
  outline: none;

  &.stretch {
    width: 100%;
  }

  &.XS {
    padding: 5px 9px;
  }

  &.S {
    padding: 8px 12px;
  }

  &.M {
    padding: 12px 24px;
  }

  &.L {
    padding: 16px 32px;
  }

  &.primary {
    background: var(--primary-color);
    color: white;

    &:hover {
      background: var(--primary-hover);
    }

    &:disabled {
      background: var(--disabled-color);
      cursor: not-allowed;
    }
  }

  &.secondary {
    background: var(--bg-secondary);
    color: var(--text-primary);
    border: none;

    &:hover {
      background: var(--primary-hover);
      color: white;
    }

    &:disabled {
      background: var(--bg-disabled);
      color: var(--text-disabled);
      cursor: not-allowed;
    }
  }

  &.outlined {
    background: none;
    outline: 1px solid var(--primary-color);
    color: var(--primary-color);

    &:hover {
      background: var(--primary-hover);
      color: white;
    }

    &:disabled {
      outline: 1px solid var(--disabled-color);
      color: var(--disabled-color);
      cursor: not-allowed;
    }
  }
}

.text,
.text.S,
.text.XS {
  background: none;
  padding: 0;
  gap: 3px;
}

.text:hover,
.text.S:hover,
.text.XS:hover {
  color: var(--primary-hover);
  cursor: pointer;
}

.text.XS {
  font-size: 12px;
  gap: 0;
}

.text:disabled,
.text.S:disabled,
.text.XS:disabled {
  color: var(--text-disabled);
  opacity: 0.5;
  cursor: inherit;
}

.rightIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}

.rightIcon svg {
  transition: transform 0.3s ease-in-out;
}

.button.S .rightIcon {
  margin-left: 5px;
}

.leftIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-top: -1px;
}

.button.S .leftIcon {
  margin-right: 0;
}

.loading {
  pointer-events: none;
  position: relative;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 16px;
  height: 16px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 0.8s linear infinite;
}

.button.S .spinner,
.button.XS .spinner {
  width: 12px;
  height: 12px;
  border-width: 1.5px;
}
