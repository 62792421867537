.inner {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  background-color: var(--bg-secondary);
  padding: 10px 15px;
  border-radius: var(--border-radius-small);
}

.icon svg {
  margin-bottom: -1.5px;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.error .inner {
  background-color: var(--bg-disabled);
}

.error .inner {
  color: #d32f2f;
  background: #ffebee;
}

.success .inner {
  color: #008000;
  background: #e8f5e9;
}

.warning .inner {
  color: #ff7700;
  background: #fff3e0;
  border: 1px solid #ff7700;
}
