.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.input {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 7px 10px;
  transition: border-color 0.2s ease-in-out;
}

.input.error {
  border-color: var(--error-color);
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.errorMessage {
  color: var(--error-color);
  font-size: 12px;
  overflow: hidden;
}

.requiredIndicator {
  color: #ff0000;
  font-size: 12px;
  margin-left: 2px;
  vertical-align: top;
}
