.sendToPlatformBtn {
  width: 100%;
}

.sketchWrapper {
  z-index: 1;
  position: absolute;
  top: 100%;
  transform: translateX(-45%);
}
