.root {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.05));
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
  }
}

.settings {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  padding: 2.5px 5px;
  border-radius: 2px;
}

.settingsButton {
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #ffffff;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  svg {
    width: 16px;
    height: 16px;
  }
}

.network {
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 2;
}

.networkIcon {
  width: 24px;
  height: 24px;
  color: #1877f2;
}

.cardLink {
  text-decoration: none;
  color: inherit;
  height: 100%;
}

.imageContainer {
  width: 100%;
  height: 160px;
  background: var(--bg-primary-soft);
  position: relative;
  overflow: hidden;
}

.imageBlurrBackground {
  position: absolute;
  top: -10px;
  left: -10px;
  width: calc(100% + 20px);
  height: calc(100% + 20px);
  background-size: cover;
  background-position: center;
  filter: blur(5px) brightness(0.8);
  transform: scale(1.1);
}

.bannerImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;
}

.placeholderImage {
  object-fit: cover;
}

.details {
  padding: 10px 15px 85px 15px;

  h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    color: #000000;
    line-height: 24px;
  }
}

.detailsBottom {
  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
}

.metaItem {
  color: #000000;
  font-size: 14px;

  svg {
    width: 16px;
    height: 16px;
    color: #000000;
  }
}

.date {
  font-size: 14px;
  color: #000000;
}

.draftStatus {
  font-size: 14px;
  line-height: 19px;
  font-weight: 800;
  color: #4b4b4b;
  text-transform: uppercase;
}
