.card {
  cursor: pointer;

  &.selected {
    outline: 2px dashed #0023ff4d;
  }

  .aiScoreText {
    font-size: 16px;
    line-height: 16px;
  }
}

.image {
  border-radius: 2px;
  overflow: hidden;
  max-height: 100%;
}

.lightbox {
  --yarl__container_background_color: rgba(0, 0, 0, 0.7);
}

.previewContainer {
  cursor: default;
  position: absolute;
  width: 450px;
  max-height: 700px;
  overflow-y: auto;
  box-shadow: 0 10px 40px 0 #00000040;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  top: 0;
  left: calc(100% + 20px);
  z-index: 10;

  .previewImageContainer {
    background-color: var(--btn-darker);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    position: relative;

    .previewImage {
      max-width: 100%;
    }

    .aiScoreContainer {
      padding: 4px 6px;
      position: absolute;
      background-color: #00000099;
      border-radius: 2px;
      color: white;

      left: 10px;
      bottom: 10px;
    }
  }
}
