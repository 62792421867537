.backLink {
  display: flex;
  align-items: center;
  gap: 8px;
  color: inherit;
  text-decoration: none;
  font-size: 16px;
}

.bannerSetsTitle {
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin: 0;
}

.breadcrumb {
  display: flex;
  align-items: center;
  color: #8d8d8d;
  gap: 5px;
}

.clientLink, .groupLink {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: #8d8d8d;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.campaignTitle {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin: 0;
  color: #000;
}

.networkIcon {
  width: 24px;
  height: 24px;
  color: #1877F2;
}

.dateRange {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.urlContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 500px;
  overflow: hidden;
}

.url {
  color: #000;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  &:hover {
    text-decoration: underline;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 7.5px 20px;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  background: #E9E9E9;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #000;
  font-family: 'Open Sans';
  
  &:hover {
    background: #e5e5e5;
  }
}

.newBannerButton {
  padding: 8px 16px;
  background: #2B3990;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  
  &:hover {
    background: darken(#2B3990, 5%);
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
}

.activeStatus {
  color: #2E7D32;
  font-size: 14px;
  font-weight: 700;
}

.inactiveStatus {
  color: #616161;
  font-size: 14px;
  font-weight: 700;
}

.platform {
  text-transform: capitalize;
}

.notes {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}
