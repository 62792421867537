@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
}

:root {
  --primary-color: #2b3990;
  --primary-hover: #2d44d6;
  --primary-soft: #e5e7ff;
  --bg-primary: #fff;
  --bg-primary-soft: #f8f8f8;
  --bg-secondary: #ecebeb;
  --bg-disabled: #f5f5f5;

  --btn-darker: #eff1f3;

  --delimiter-color: #0e0b0b;

  --error-color: #ff0000;
  --error-soft: #e35050;

  --text-disabled: #808080;
  --border-disabled: #e0e0e0;

  --text-faded-color: #909090;

  --crumb-color: #888888;
  --border-radius-xs: 2px;
  --border-radius-small: 5px;
}

img {
  max-width: 100%;
}

.color-primary {
  color: var(--primary-color);
}
