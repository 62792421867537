.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
}

.name {
  font-size: 16px;
  margin: 0;
}

.subCategories {
  font-size: 12px;
  color: #666666;
  line-height: 16px;
}

.header {
  position: relative;
}

.removeButton {
  position: absolute;
  right: 0;
  top: 5px;
}
