.infoStatsElement {
  display: flex;
  align-items: center;
  gap: 5px;
}

.infoStatsElement svg {
  color: var(--primary-color);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
}

.progressBar {
  width: 100%;
  max-width: 500px;
  height: 30px;
  background-color: #f0f0f0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
}

.progressBarFill {
  height: 100%;
  background-color: var(--primary-color);
  transition: width 0.3s ease-in-out;
  position: relative;
}

.progressBarText {
  color: #fff;
  font-weight: bold;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.progressBarTextHolder {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 40px;
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.progressStats {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.progressTitle {
  margin: 0;
}

.progressInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
  margin-bottom: 40px;
}

.errorIcon {
  color: var(--error-soft);
  font-size: 100px;
  padding: 20px;
}
