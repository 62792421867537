.container {
  width: 100%;
  overflow: hidden;
}

.scaleWrapper {
  min-height: 200px;
}

.preview {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* transform-origin: top left;
  will-change: transform; */
}

.selectedElement {
  outline: var(--primary-color) inset 8px;
}
