.banner-preview-container {
  h1,
  h2,
  h3,
  h4 {
    display: inline;
  }
}

.moveable {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  text-align: center;
  font-weight: 100;
  cursor: pointer;

  &.image-item {
    width: 200px;
    height: 100px;
  }

  &.text-item {
    display: block;
    line-height: 1.1;
    // white-space: nowrap;
    padding: 5px 5px;
  }
}

.moveable-control {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-sizing: border-box;
  background: #4af;
  margin-top: -7px;
  margin-left: -7px;
  z-index: 10;
}

.banner-element-holder {
  &:hover {
    .moveable-control-box {
      opacity: 1;
    }
  }
  .moveable-control-box {
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }
}

.moveable-rotation {
  zoom: 1.5;
  background: #20a0ff;
}

.label {
  position: fixed;
  top: 0;
  left: 0;
  padding: 5px;
  border-radius: 5px;
  background: #333;
  z-index: 3001;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  display: none;
  transform: translate(-100%, -100%);
}

.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

.banner-preview-container {
  background-color: white;
}
