.root {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.colorOption {
  background-color: var(--bg-disabled);
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--bg-disabled);
  position: relative;
}

.colorPreview {
  width: 100%;
  height: 25px;
  border-radius: 2px;
  margin-bottom: 5px;
  cursor: pointer;
}

.colorsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}

.colorName {
  color: var(--text-faded-color);
}

.footer {
  font-size: 12px;
  color: var(--text-faded-color);
  display: grid;
  grid-template-columns: 1fr 14px 14px;
  gap: 5px;
  align-items: center;
}

.previewActive {
  border-color: var(--primary-color);
  background-color: var(--primary-soft);
}

.colorOption:hover {
  background-color: var(--primary-soft);
}

.clickableWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.editButton,
.removeButton {
  position: relative;
}
