.buttonsBar {
  display: grid;
  grid-template-columns: 310px 1fr;
  justify-content: space-between;
  align-items: center;
}

.resolutionButtons {
  display: flex;
  justify-content: flex-end;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
