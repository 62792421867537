.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.label {
  font-size: 12px;
  color: #666;
  display: block;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.actions {
  display: flex;
  gap: 5px;
}

.whiteBox {
  background-color: var(--bg-primary);
  padding: 10px;
  border-radius: var(--border-radius-small);
  position: relative;
}

.boxClickableOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--bg-primary);
}

.boxClickableOverlay:hover {
  border-color: var(--delimiter-color);
}

.active .boxClickableOverlay {
  border-color: var(--primary-color);
}

.inner {
  position: relative;
}
