.container {
  display: flex;

  label {
    display: flex;

    .input {
      display: none;

      &:checked + .label {
        background-color: var(--primary-color);
        border: 2px solid var(--primary-color);

        &::before {
          background-color: white;
          transform: translateX(20px);
        }
      }
    }

    .label {
      background-color: transparent;
      border: 2px solid black;
      display: inline-block;
      width: 40px;
      height: 20px;
      border-radius: 10px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        width: 12px;
        height: 12px;
        background-color: black;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
        transform: translateX(0);
        transition: all 0.4s ease-in-out;
      }
    }
  }
}
