.root {
  position: relative;
  padding: 50px 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  width: 100%;

  h3, p {
    margin: 0;
  }
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

  h3 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    color: #8D8D8D;
    font-weight: 400;
    line-height: 19px;
  }

  span {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

.detailsBottom {
  position: absolute;
  bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.notes {
  color: #8D8D8D;
}

.campaigns {
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 16px;
    height: 16px;
  }

  p {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 21px;
  }
}

.settings {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  gap: 5px;
}

.networks {
  position: absolute;
  top: 15px;
  left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
}

.networkButton {
  background-color: transparent;
  border: none;
}

.settingsButton {
  background-color: transparent;
  border: none;
}

.settingsButton:hover {
  cursor: pointer;
}

.link:hover {
  cursor: pointer;
}

body .active {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #2B9068;
}

body.inactive {
  font-size: 14px;
  line-height: 19px;
  font-weight: 700;
  color: #616161;
}

.domain {
  font-size: 16px;
  line-height: 21px;
  font-weight: 400;
  color: #000000;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 200px;
}

.campaigns {
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 16px;
    height: 16px;
  }

  p {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 21px;
  }
}

.cardLink {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
