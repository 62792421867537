.root {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.tag {
  background-color: var(--bg-disabled);
  padding: 2px 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 1px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--bg-disabled);
}

.tagText {
  font-size: 14px;
  color: var(--text-color);
  padding-bottom: 2px;
  line-height: 15px;
}

.input {
  border: 1px solid var(--border-color);
  font-size: 12px;
  width: 100%;
}

.input:focus {
  outline: none;
  border-color: var(--primary-color);
}

.previewActive {
  border-color: var(--primary-color);
  background-color: var(--primary-soft);
}

.tag:hover {
  background-color: var(--primary-soft);
}
