.clientName {
  font-size: 20px;
  font-weight: 400;
  line-height: 27.24px;
}

.noCampaigns {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #8d8d8d;
}

.campaignsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
}

.campaignsContainer > div {
  width: 100%;
}
