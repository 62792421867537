.root {
  line-height: 1.5;
  position: relative;
}

.title {
  font-size: 14px;
  color: var(--text-faded-color);
  display: flex;
  align-items: center;
  gap: 6px;
}

.title svg {
  margin-bottom: -1.5px;
  color: var(--primary-color);
}

.content {
  font-size: 16px;
}

.id {
  font-size: 12px;
  color: var(--text-faded-color);
  display: block;
}

.content,
.id {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.content::after,
.id::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 40px;
  background: linear-gradient(to right, transparent, var(--bg-primary-soft));
  pointer-events: none;
}
