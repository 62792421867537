@import '../../../styles/variables.scss';

.wrapper {
  padding: 24px;
}

.cardsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.card {
  margin-bottom: 24px;
}

.backLink {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-secondary);
  text-decoration: none;
  margin-bottom: 24px;
  font-size: 14px;

  &:hover {
    color: var(--text-primary);
  }
}

.submitFooter {
  display: flex;
  justify-content: flex-start;
  padding-top: 25px;
}

.submitButton {
  display: flex;
  align-items: center;
  gap: 33px;
  padding: 7.5px 20px;
  background: $primary-color;
  border: 1px solid $primary-color;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.2s ease;

  svg > rect {
    stroke: #fff;
  }

  &:hover {
    background-color: var(--primary-hover);
    gap: 36px;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.errorMessage {
  margin-bottom: 24px;
}

.messageBox {
  margin-top: 25px;
}
