.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.scaleWrapper {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.preview {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform-origin: center center;
  will-change: transform;
}

.selectedElement {
  outline: var(--primary-color) inset 8px;
}

.disabledActions {
  pointer-events: none;
}
