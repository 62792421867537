.root {
  position: relative;
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  width: calc(100% / 4 - 15px);
  padding-bottom: 70px;

  h3, p {
    margin: 0;
  }
}

.logoBox {
  width: 100%;
  min-height: 65px;
  display: flex;
  align-items: center;
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 65px;
  max-height: 65px;
  object-fit: contain;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;

  h3 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    color: #8D8D8D;
    font-weight: 400;
    line-height: 19px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    line-height: 1.4;
    max-height: 5.6em;
  }

  span {
    color: #000;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

.descriptionTop, .descriptionBottom {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.descriptionBottom {
  position: absolute;
  bottom: 15px;
}

.notes {
  color: #8D8D8D;
}

.campaigns {
  display: flex;
  align-items: center;
  gap: 5px;

  svg {
    width: 16px;
    height: 16px;
  }

  p {
    font-size: 16px;
    color: #000;
    font-weight: 400;
    line-height: 21px;
  }
}

.settings {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  gap: 5px;
}

.settingsButton {
  background-color: transparent;
  border: none;
}

.settingsButton:hover {
  cursor: pointer;
}

.link:hover {
  cursor: pointer;
}
