.resolutionLabel {
  font-size: 12px;
}

.imageWrapper {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: var(--border-radius-xs);
  background-color: var(--bg-secondary);
  position: relative;
}

.image {
  border-radius: var(--border-radius-xs);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: calc(100% - 10px);
  max-height: calc(100% - 10px);
}

.noImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.noImageText {
  font-size: 12px;
  color: var(--text-faded-color);
}

.aspectRatioContainer {
  min-width: 50px;
  width: 100%;
  max-width: 110px;
  text-align: center;
}

.aspectRatioWrapper {
  position: relative;
  width: 100%;
  max-height: 110px;
  overflow: hidden;
  margin: 0 auto;
}

.aspectRatioIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-xs);
  border: 1px dashed rgba(0, 0, 0, 0.4);
}

.disabled .imageContainer {
  opacity: 0.5;
}

.disabled .disableButton {
  color: var(--error-soft);
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cloudUpload {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.overlayMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 80%);
  border-radius: var(--border-radius-xs);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.overlayMenu:hover {
  opacity: 1;
}

.overlayMenuItem {
  color: white;
}

.actions {
  display: flex;
  gap: 5px;
}

.notEditable {
  pointer-events: none;
}
