.title {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  line-height: 20px;
}

.card {
  position: relative;
  background: var(--bg-primary);
  border-radius: 5px;
  padding: 15px;
}

.platform {
  font-size: 18px;
  line-height: 18px;
}

.closeIcon {
  color: var(--error-color);
  font-size: 16px;
}

