.contentWrapper {
  display: grid;
  grid-template-columns: 270px 1fr;
  gap: 30px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.rightPreviewWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  max-width: 900px;
}
