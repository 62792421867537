.root {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: #000;
}

.infoArray {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.infoElement {
  display: flex;
  align-items: center;
  gap: 5px;
}

.infoElement svg {
  color: var(--primary-color);
}
