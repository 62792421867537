.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .button {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #f8f8f8;
    border-radius: 5px;
    padding: 5px 10px;
    border: 1px solid #f8f8f8;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    position: relative;

    &.darker {
      background: #eff1f3;
    }

    &.active {
      background: var(--primary-color);
      color: white;
      border-color: var(--primary-color);
    }

    &:hover {
      background: var(--primary-hover);
      color: white;
      border-color: var(--primary-hover);
    }
  }
}

.errorMessage {
  color: var(--error-color);
  font-size: 12px;
  overflow: hidden;
  margin: 0;
}

.disabledIcon,
.errorIcon {
  color: var(--error-color);
  background: #fff;
  border-radius: 100px;
  border: 1.5px solid #fff;
}

.errorIcon {
  color: var(--error-color);
  background: #fff;
  border-radius: 1px;
  border: 1.5px solid #fff;
}

.overIcons {
  position: absolute;
  font-size: 16px;
  top: -9px;
  right: 5px;
}

.error {
  border-color: var(--error-color);
}
