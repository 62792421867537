.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 700px;
  padding: 10px 0;
}

.title {
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 600;
}

.actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 15px;
}

.resolution {
  padding: 10px;
  border: 1px dotted #e0e0e0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  background-color: #f5f5f5;
}

.uploadedFiles {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
}

.uploadedFile {
  position: relative;
}

.uploadedFile img {
  border-radius: 4px;
}

.root .removeButton {
  position: absolute;
  top: -2px;
  right: -2px;
  border-radius: 50%;
  padding: 2px;
}

.description {
  margin: 0 0 10px;
}

.resolutionActions {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
  align-items: center;
}

.resolutionsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
}

.disabled {
  background-color: #f5f5f534;
}

.disabled .uploadedFiles,
.disabled .resolutionActions {
  opacity: 0.5;
  pointer-events: none;
}

.disabledTag {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: var(--error-soft);
  margin-left: 5px;
}

.uploading {
  opacity: 0.5;
  pointer-events: none;
}
