.color {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid var(--bg-secondary);
}

.option {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  margin: 0 10px 5px 0;
  line-height: 14px;
  font-size: 12px;
  float: left;
}

.option > svg {
  margin-bottom: -1px;
}
