.btn-default {
  font-family: 'Open Sans';
  font-size: inherit;
  font-weight: 600;
  text-align: center;
  display: inline-flex;
  align-items: center;
  height: 40px;
  width: fit-content;

  text-transform: uppercase;
  background: white;
  cursor: pointer;
  box-shadow: none;
  padding: 1rem 2rem;
  border: none;
  color: black;
  border: solid 2px lightgray;
  align-items: center;
  justify-content: center;

  transition: all 0.2s ease-out;
  &:hover,
  &:active {
    color: white;
    background: #52bad5;
    border: solid 2px white;
  }
}

.gray-btn-modal {
  padding: 8px 25px;
  border-radius: 5px;
  background: lightgray;
  color: #000;
  margin-left: 10px;
  border: 2px solid transparent;
  font-weight: bold;

  &:hover {
    color: lightgray;
    cursor: pointer;
    background: none;
    border: 2px solid lightgray;
  }
}

.alert-btn {
  background: orangered;
  color: white;
  border: 2px solid orangered;
  &:hover {
    cursor: pointer;
    background: none;
    color: orangered;
    border: 2px solid orangered;
  }
}

.modal-close-button {
  position: absolute;
  top: -15px;
  right: -15px;
  background: none;
  border: none;
  cursor: pointer;
  background: black;
  padding: 0;
  border-radius: 100px;
  padding: 5px;
}

.modal-close-button svg {
  padding: 0;
  margin: 0;
  float: left;
}

.modal-title {
  margin: 0 0 10px;
}
