.contextMenu {
  position: fixed;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  min-width: 160px;
  z-index: 1000;
}

.menuItem {
  padding: 5px 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #333;
}

.menuItem:not(.id) {
  cursor: pointer;
}
.menuItem:not(.id):hover {
  background-color: #f5f5f5;
}

.menuItem.disabled {
  opacity: 0.5;
  cursor: default;
}

.divider {
  height: 1px;
  background-color: #eee;
  margin: 4px 0;
}

.menuItem.id {
  font-size: 12px;
}
