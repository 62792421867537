.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.select {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  padding: 6px 10px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select.error {
  border-color: var(--error-color);
}

.errorMessage {
  color: var(--error-color);
  font-size: 12px;
  overflow: hidden;
}
