.root {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}

.root .advancedButton {
  border-bottom: 1px solid gray;
  border-radius: 0;
  gap: 0;
  padding-bottom: 2px;
}

.toggledCustomCss svg {
  transform: scaleY(-1);
}

.root.disabled > * {
  opacity: 0.25;
  pointer-events: none;
}

.input input,
.input textarea {
  width: 100%;
}
