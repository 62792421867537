.formContainer {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px 30px;
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  margin-bottom: 20px;
}

.formHeader {
  font-size: 22px;
  font-weight: 700;
  margin: 0 0 10px;
}

.formDoubleRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.formInput {
  width: 100%;
}

.resolutionsContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;
  width: 100%;
}
