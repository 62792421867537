.adTypesWrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 15px;
}

.adTypeBox {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 10px 15px;
  border-radius: 5px;
  text-align: center;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  border: 2px solid white;
}

.adTypeBox:hover {
  border-color: var(--primary-hover);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--primary-soft);
  color: var(--primary-hover);
}

.adTypeBox.active {
  border-color: var(--primary-color);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--primary-soft);
  color: var(--primary-color);
}
