.imagesContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  border-radius: var(--border-radius-small);
  position: relative;
}

.notEditable {
  pointer-events: none;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.boxClickableOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  border: 1px dashed var(--bg-primary);
}

.boxClickableOverlay:hover {
  border-color: var(--delimiter-color);
}

.active .boxClickableOverlay {
  border-color: var(--primary-color);
}
