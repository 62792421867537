.root {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.inputGroup {
  display: flex;
  align-items: center;
  gap: 5px;
}

.buttonGroup {
  display: flex;
  gap: 5px;
}

.addCustomButton {
  background: none;
  border: none;
  border-bottom: 1px solid #7f7f7f;
  color: #7f7f7f;
  cursor: pointer;
  padding: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.addCustomButton:hover {
  color: var(--primary-hover);
}

.saveButton {
  padding: 8px 10px;
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.saveButton:hover {
  background: var(--primary-hover);
  color: white;
}

.cancelButton {
  padding: 8px 10px;
  background: var(--bg-secondary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
}

.cancelButton:hover {
  background: var(--primary-hover);
  color: white;
}

.errorShake {
  animation: errorShake 0.3s ease-in-out, errorBorder 3s ease-in-out;
}

@keyframes errorShake {
  0%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-4px);
  }
  75% {
    transform: translateX(4px);
  }
}

@keyframes errorBorder {
  0% {
    border-color: var(--error-color);
  }
  80% {
    border-color: var(--error-color);
  }
  100% {
    border-color: #e0e0e0;
  }
}
