.uploadWrapper {
  position: relative;
  width: 100%;
}

.uploadBox {
  position: relative;
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  height: 120px;
}

.uploadContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #eee;
  cursor: pointer;
  transition: all 0.2s ease;
}

.uploadContent:hover {
  background: #e0e0e0;
}

.uploadContent.hasImage {
  background: none;
}

.uploadContent img {
  max-width: calc(100% - 10px);
  max-height: calc(100% - 10px);
}

.resolutionLabel {
  font-size: 12px;
  color: #666;
}

.fileUploader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  display: block;
}

.fileUploaderInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block !important;
  cursor: pointer;
}

.error {
  color: #d32f2f;
  margin-bottom: 20px;
  padding: 5px 10px;
  background: #ffebee;
  border-radius: 4px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  gap: 15px;
}

.actionButton {
  width: 140px;
  justify-content: center;
}

.hasError {
  background: rgba(227, 80, 80, 0.1);
  border: 1px solid rgba(227, 80, 80, 0.1);
}

.inner {
  position: relative;
  padding: 5px;
  border-radius: 5px;
}

.disableButton {
  background: none;
  border: none;
  color: var(--text-faded-color);
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.disableButton svg {
  margin-top: -2px;
}

.resolutionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.isDisabled > *:not(.resolutionWrapper) {
  opacity: 0.25;
  pointer-events: none;
}

.isDisabled .disableButton {
  color: var(--error-soft);
}

.copyButtons {
  margin-top: 10px;
}

.reflectIcon {
  transform: scaleX(-1);
}

.buttonGroup {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.advancedSection {
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
  background: #f5f5f5;
}
