.root {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.borderToggle {
  margin: 10px 0;
}

.remove {
  color: var(--error-soft);
}
