.root {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.networkButton {
  border: none;
  background: var(--bg-primary-soft);
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.networkButton:hover {
  background: #f5f5f5;
}

.networkButton.active {
  background: var(--primary-color);
  color: white;
}

.label {
  font-weight: 400;
  font-size: 14px;
  color: #000;
}

.inner {
  display: flex;
  gap: 1rem;
}

.errorMessage {
  color: var(--error-color);
  font-size: 12px;
  overflow: hidden;
}
