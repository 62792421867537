.container {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 20px auto;
}

.content {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.randomChars {
  position: relative;
  width: 100%;
  height: 100%;
  color: var(--primary-color);
}

.randomChars span {
  position: absolute;
  font-size: 14px;
  opacity: 0;
  left: 50%;
  top: 50%;
  transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
  animation: fadeInChar 0.5s ease forwards;
  animation-delay: var(--delay);
  animation-fill-mode: forwards;
}

.imageIcon {
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;
  color: var(--primary-color);
}

.phase1 {
  animation: morphToRandom 0.5s ease-in-out;
}

.phase2 {
  animation: morphToImage 0.5s ease-in-out;
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes morphToRandom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes morphToImage {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
