.confirmModal {
  padding: 10px;
}

.title {
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 600;
}

.message {
  margin-bottom: 10px;
  color: #333;
}

.subMessage {
  color: #666;
  display: block;
  margin-bottom: 8px;
}

.actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 15px;
}

.actions .dangerButton {
  background-color: var(--error-color);
}
