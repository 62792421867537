.dropdown {
  position: relative;
  display: inline-block;
}

.content {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 200px;
  margin-top: 5px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  animation: dropdownFadeIn 0.2s ease-out;
  padding: 10px 0;
}

.item {
  display: block;
  width: 100%;
  padding: 10px 15px;
  text-align: left;
  border: none;
  background: none;
  transition: background-color 0.2s ease-in-out;

  &:hover:not(.disabled) {
    background-color: var(--bg-secondary);
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.75;
  }
}

@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
